import { ChangeDetectionStrategy, Component, effect, HostBinding, signal } from '@angular/core';

@Component({
  selector: 'clients-outlet-layout',
  templateUrl: './clients-outlet-layout.component.html',
  styleUrls: ['./clients-outlet-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientsOutletLayoutComponent {
  private __$sidenavExpanded = signal(this.__getSidenavStorage());
  readonly $sidenavExpanded = this.__$sidenavExpanded.asReadonly();

  constructor() {
    effect(() => this.__setSidenavStorage(this.$sidenavExpanded()));
  }

  get isSidenavOpen(): boolean {
    return this.__$sidenavExpanded();
  }

  @HostBinding('class') get cssClass(): string[] {
    const cssClass = ['d-flex', 'flex-grow-1', 'flex-column', 'align-items-stretch', 'justify-content-stretch'];
    this.__$sidenavExpanded() && cssClass.push('sidenav-expanded');
    return cssClass;
  }

  toggleSidenav(): void {
    this.__$sidenavExpanded.set(!this.__$sidenavExpanded());
  }

  private __getSidenavStorage(): boolean {
    const state = localStorage.getItem('sidenav-state');
    return state === null || state === 'true';
  }

  private __setSidenavStorage(value: boolean): void {
    localStorage.setItem('sidenav-state', String(value));
  }
}
